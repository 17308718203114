import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { db } from "./siteVars"
import { Link } from "gatsby"
import Button from "./button"
import Axios from "axios"
import popupAlert from "./popup-alert"

const StyledPayPal = styled.div``

const PayPal = props => {
  const [loaded, error] = useScript(
    "https://www.paypal.com/sdk/js?client-id=AYBb48NGXPK8FnRbGQotsxLAAhlD6DplfYE9tWpJAF0cEBS8ekzS5oGBfRNmOV_LfWyK-C4paV9316NL"
  )
  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.paypal) {
        if (error) {
          console.log(error)
        }
        window.paypal
          .Buttons({
            createOrder: function (data, actions) {
              // This function sets up the details of the transaction, including the amount and line item details.
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: "0.01",
                    },
                  },
                ],
                application_context: {
                  shipping_preference: "NO_SHIPPING",
                },
              })
            },
            onAuthorize: function () {
              console.log("heyyoo")
            },

            onCancel: function (data) {
              popupAlert("The payment was cancelled.")
            },
            onApprove: function (data, actions) {
              // This function captures the funds from the transaction.
              return actions.order.capture().then(function (details) {
                // This function shows a transaction success message to your buyer.

                props.triggerAlert(
                  <>
                    <p>Thank you {details.payer.name.given_name}!</p>
                    {props.upgrade ? (
                      <>
                        <p>Your account has been upgraded</p>
                        <Link to="/planner">
                          <Button>Go to planner</Button>
                        </Link>
                      </>
                    ) : (
                      <>
                        <p>
                          Your pro account has been created, and you can log in
                          using the button below.
                        </p>
                        <Link to="/login">
                          <Button>Sign in</Button>
                        </Link>
                      </>
                    )}
                  </>
                )
                const body = {
                  user: props.userToAdd,
                }
                if (props.upgrade) {
                  Axios.post(
                    db.lambda +
                      "users/addproaccountafterpaymentvalidatedupgrade/" +
                      props.currentUser._id
                  )
                    .then(res => {
                      console.log(res.data)
                    })
                    .catch(err => {
                      console.log(err.response.data)
                    })
                } else {
                  Axios.post(
                    db.lambda + "users/addproaccountafterpaymentvalidated",
                    body
                  )
                    .then(res => {
                      console.log(res.data)
                    })
                    .catch(err => {
                      console.log(err.response.data)
                    })
                }
              })
            },
          })
          .render("#paypal-button-container")
        //This function displays Smart Payment Buttons on your web page.
      }
    }
  }, [loaded])
  return (
    <StyledPayPal className={props.disabled ? "disabled" : ""}>
      <div id="paypal-button-container"></div>
    </StyledPayPal>
  )
}

let cachedScripts = []
function useScript(src) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        })
      } else {
        cachedScripts.push(src)

        // Create script
        let script = document.createElement("script")
        script.src = src
        script.async = true

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          })
        }

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src)
          if (index >= 0) cachedScripts.splice(index, 1)
          script.remove()

          setState({
            loaded: true,
            error: true,
          })
        }

        script.addEventListener("load", onScriptLoad)
        script.addEventListener("error", onScriptError)

        // Add script to document body
        document.body.appendChild(script)

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad)
          script.removeEventListener("error", onScriptError)
        }
      }
    },
    [src] // Only re-run effect if script src changes
  )

  return [state.loaded, state.error]
}
export default PayPal
