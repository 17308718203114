import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Axios from "axios"
import { db, colors } from "../components/siteVars"
import { navigate } from "gatsby"
import Contentbox from "../components/contentbox"
import Input from "../components/input"
import Button from "../components/button"
import Iframe from "../components/iframe"
import popupAlert from "../components/popup-alert"
import Loader from "../components/loader"
import PayPal from "../components/paypal"
import LargeAlertBox from "../components/large-alert-box"
import { signedIn } from "../components/handle-auth"

const StyledProPage = styled.div`
  display: grid;
  grid-template-columns: 4fr 10fr;
  grid-gap: 1rem;
  form {
    display: flex;
    flex-direction: column;
  }
  h1 {
    margin-top: 0;
    &.h1-less-margin {
      margin-bottom: 0;
    }
  }
  .go-to-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .centered-text {
    text-align: center;
  }
  .get-pro-button {
    width: 100%;
    display: block;
    button {
      width: 100%;
    }
  }
  .goto-pay-button {
    margin-bottom: 1rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const LoginPageLoader = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`

export default class ProPage extends Component {
  state = {
    formComplete: false,
    alertActive: false,
    alertContent: <br />,
    username: "",
    email: "",
    password: "",
    passwordRepeat: "",
    loading: false,
    userToAdd: {},
  }

  componentDidMount() {
    if (signedIn()) {
      navigate("/upgrade")
    }
    setTimeout(() => {
      console.log(document.getElementById("paypalloader"))
    }, 100)
  }

  checkPassMatch = (a, b) => {
    if (a === b) return true
    else return false
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit = e => {
    e.preventDefault()
    const passMatch = this.checkPassMatch(
      this.state.password,
      this.state.passwordRepeat
    )
    if (!passMatch) {
      popupAlert("Passwords don't match")
      return
    }
    const body = {
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      permissions: "proUser",
    }
    Axios.post(db.lambda + "users/validateRegistration", body)
      .then(res => {
        if (res.data.user) {
          this.setState({
            formComplete: true,
            userToAdd: res.data.user,
          })
        } else {
          popupAlert("There was an error. Please try again")
        }
      })
      .catch(err => {
        popupAlert(err.response.data)
      })
  }

  triggerAlert = content => {
    this.setState({
      alertActive: true,
      alertContent: content,
    })
  }

  render() {
    const link = `
    <iframe
    width="1280"
    height="720"
    src="https://www.youtube.com/embed/wcBNXB69YL8"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    />
    `
    return (
      <>
        <LargeAlertBox
          active={this.state.alertActive}
          content={this.state.alertContent}
        />
        <LoginPageLoader className={this.state.loading ? "active" : ""}>
          <Loader dualRing color={colors.mediumGrey} />
        </LoginPageLoader>
        <Layout background>
          <StyledProPage>
            <Contentbox background>
              <h1 className="h1-less-margin">Teamgym.se PRO</h1>
              <p>Get a PRO account to build your own programs for only 2€!</p>
              <form onSubmit={this.onSubmit}>
                <Input
                  placeholder="Email"
                  label="Email"
                  name="email"
                  onChange={this.onChange}
                  value={this.state.email}
                  type="text"
                />
                <Input
                  placeholder="Username"
                  label="Username"
                  name="username"
                  onChange={this.onChange}
                  value={this.state.username}
                  type="text"
                />
                <Input
                  placeholder="Password"
                  label="Password"
                  name="password"
                  onChange={this.onChange}
                  value={this.state.password}
                  type="password"
                />
                <Input
                  placeholder="Repeat password"
                  label="Repeat password"
                  name="passwordRepeat"
                  onChange={this.onChange}
                  value={this.state.passwordRepeat}
                  type="password"
                />
                <Button color="green" className="goto-pay-button">
                  Go to payment
                </Button>
                {this.state.formComplete ? (
                  <PayPal
                    triggerAlert={this.triggerAlert}
                    userToAdd={this.state.userToAdd}
                  />
                ) : null}
              </form>
              {/* <div className="go-to-login">
                <p>Already have an accout?</p>
                <Link to="/login">
                  <Button type="button">Log in</Button>
                </Link>
              </div> */}
            </Contentbox>
            <Iframe>{link}</Iframe>
          </StyledProPage>
        </Layout>
      </>
    )
  }
}
